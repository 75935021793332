<template src="./CreateCompanyPage.html"></template>
<style scoped lang="scss" src="./CreateCompanyPage.scss"></style>

<script>

const CreateCompanyForm = () => import('@/components/form/company-form/CompanyForm')
import { ServiceFactory } from "@/services";
const UserService = ServiceFactory.get('user')
export default {
  name: 'CreateCompany',
  components: { CreateCompanyForm },
  data: function () {
    return {
      isLoading: false,
      userData: {},
    }
  },
  methods: {
    handleOnFormCancel() {
      this.$router.push({ name: 'routes.companyManagement.create' })
    },
    prepareData(data) {
      return {
        brandId: data.brandId || 0,
        email: data.email || '',
        savedFolder: data.savedFolder || '',
        isSendMail: data.isSendMail || '',
      }
    },
    async handleOnFormSubmit(formData) {

      const data = this.prepareData(formData)

      this.isLoading = true
      const newUser = await UserService.create(data).catch(() => null)
      this.isLoading = false

      if (newUser && newUser.id) {
        this.$notify({
          message: '会社情報の登録は完了しました。',
          type: 'success'
        })

        // this.userData = {}
      }
    }
  }
}
</script>
